import { FunctionalComponent } from "preact";
import { useMemo } from "preact/hooks";

import {
    Logo,
    MoneyEntry,
    PDFSidebarEntry,
    SettingsBar,
    SidebarEntry,
} from "@/components";
import { useSettings } from "@/hooks";
import { Icon } from "@/icons";
import { Color } from "@/types";
import { classBuilder, getEntries } from "@/utils";

import { entries } from "@/data/sidebar";
import { type IconKey, iconOff } from "@/icons/base";
import { SettingKey, Settings, settingsData } from "@/state/settings";

import "./style.css";

const switchColors: Record<SettingKey, Color> = {
    monospace: "orange",
    lowercase: "blue",
    compact: "green",
    contrast: "red",
    colorful: "cyan",
    neon: "pink",
    reversed: "purple",
};

const switchIcons: Record<SettingKey, [IconKey, IconKey]> = {
    monospace: ["proportional", "monospace"],
    lowercase: ["normalcase", "lowercase"],
    compact: ["expand", "minimize"],
    contrast: iconOff("contrast"),
    colorful: iconOff("colors"),
    neon: iconOff("bulb"),
    reversed: ["normalsort", "reversedsort"],
};

const classname = classBuilder("sidebar");
const element = classname.element;

const buildSwitches = (settings: Settings) => {
    return getEntries(settings).map(([key, value]) => ({
        name: settingsData[key].name,
        key,
        value,
        color: switchColors[key],
        shown: true,
        description: settingsData[key].description,
        icons: switchIcons[key],
    }));
};

export const Sidebar: FunctionalComponent = () => {
    const [settings, setSettings] = useSettings();

    const switches = useMemo(() => buildSwitches(settings), [settings]);

    const entriesContent = entries.map((e) => <SidebarEntry data={e} />);

    entriesContent.splice(1, 0, <MoneyEntry />, <PDFSidebarEntry />);

    return (
        <div className="sidebar-portal">
            <SettingsBar
                data={switches}
                onClick={setSettings}
                title="Settings"
                icons={["settings", "settings"]}
            />
            <section className={classname.color("blue").build()}>
                <div className={element("header").build()}>
                    <div className={element("logo").build(classname.card)}>
                        <Logo />
                    </div>
                    <div
                        className={element("header-text").build(classname.card)}
                    >
                        <h1 className={element("name").build()}>
                            Dmitry Gritsenko
                        </h1>
                        <h2 className={element("specialization").build()}>
                            <span
                                className={classBuilder("")
                                    .color("orange")
                                    .build()}
                            >
                                <Icon iconKey="react" />
                                Front-end&nbsp;Developer
                            </span>
                            <br />
                            <span>
                                <Icon iconKey="python" /> Python&nbsp;Developer
                            </span>
                        </h2>
                    </div>
                </div>
                <div className={element("info").build()}>{entriesContent}</div>
            </section>
        </div>
    );
};
